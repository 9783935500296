import React, { memo } from "react";
import EboRoutes from "./EboRoutes";

import { BrowserRouter as Router } from "react-router-dom";
import Modals from "./Modals";
import TopNavHeadlines from "../assets/navbarRelated/TopNavHeadlines";

const EboRouter = () => {
  return (
    <Router>
      <Modals />
      <TopNavHeadlines />
      <EboRoutes />
    </Router>
  );
};

export default memo(EboRouter);
