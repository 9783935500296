import { createContext, useEffect, useState } from "react";
import { get_hubs_by_query_obj } from "../api/hubApi";

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [orders, setOrders] = useState({
    isLoading: false,
    orders: [],
    setOrders: 0,
  });

  const [storeStates, setStoreStates] = useState({
    defaultHub: null,
    defaultLocation: null,
    navHeadline: {
      heading: "",
      subHeading: "",
      component: null,
    },
    isGoogleMapsScriptLoaded: false,
  });
  // -------------------- constants --------------------

  const BaseUrl = process.env.REACT_APP_API_URL;

  // ------------------------ useEffect  ------------------------------
  useEffect(() => {
    if (!storeStates.defaultHub) {
      handleHubsFetch();
    }
    // eslint-disable-next-line
  }, []);
  // ----------------------- functions -------------------------
  const handleHubsFetch = async () => {
    try {
      const res = await get_hubs_by_query_obj({
        queryObject: { city: "siliguri" },
      });
      if (res.isSuccess && res.hubs.length > 0) {
        setStoreStates((p) => ({
          ...p,
          defaultHub: res.hubs[0],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StoreContext.Provider
      value={{ BaseUrl, orders, setOrders, storeStates, setStoreStates }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
