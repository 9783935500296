import { memo, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AdminContext from "../context/AdminContext";

const AdminPrivateRoute = () => {
  const { LC_TOKEN } = useContext(AdminContext);

  return LC_TOKEN ? <Outlet /> : <Navigate to="/signin" />;
};

export default memo(AdminPrivateRoute);
