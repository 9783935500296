import axios from "axios";

const BaseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("ACCESS_TOKEN");

/**
 * for sign up
 * @param {Object} {BaseUrl,userName, password, mobile,eboCode}
 * @return {Object}
 */
const signUpApi = async ({ userName, password, mobile, eboCode }) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/admin/user/signup`, {
      userName,
      password,
      mobile,
      eboCode,
    });
    if (res.data.isSuccess) {
      return res.data;
    } else {
      return {
        isSuccess: false,
        data: null,
        isAlreadyExist: res.data.isAlreadyExist,
      };
    }
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

/**
 * for sign in
 * @param {Object} {password, mobile}
 * @return {Object}
 */
const signInApi = async ({ password, mobile }) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/admin/user/signin`, {
      password,
      mobile,
    });
    if (res.data.isSuccess) {
      return res.data;
    } else {
      return {
        isSuccess: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

// --------> for getting the users information on the basis of query
const get_user_by_query_obj = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/user/get_user_by_query_obj`,
      { queryObject, skip, limit, sortingObj },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};
// -------->creating an access token for the admin to the main website with another's user information
const create_user_access_token = async ({ userId }) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/user/create_user_access_token/${userId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};
const handle_creating_new_user = async ({ formData }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/user/handleCreatingNewUser`,
      { ...formData },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error(error.message);
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

//-----------getting USer Cart ---------------
const get_cart_by_query_obj = async ({ queryObject, skip, limit }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/cart/get_cart_by_query_obj`,
      { queryObject, skip, limit },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
}; //-----------getting USer Cart ---------------
const adminUserProfilePageInitialDataApi = async (userId) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/user/admin-user-profile-page-initial-data`,
      { userId },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};

export const DeleteUserAndProperties = async (id) => {
  try {
    const res = await axios.get(`${BaseUrl}/api/admin/user/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (e) {
    return { isSuccess: false, message: e.message };
  }
};
export const adminUserPageInitialData = async () => {
  try {
    const res = await axios.get(
      `${BaseUrl}/api/admin/user/admin-user-page-initial-data`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (e) {
    return { isSuccess: false, message: e.message };
  }
};


export const update_user_by_query_obj = async ({
  queryObject,
  updateObject,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/user/update_user_by_query_obj`,
      { queryObject, updateObject },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return {
      isSuccess: false,
    };
  }
};

export {
  signUpApi,
  signInApi,
  get_user_by_query_obj,
  create_user_access_token,
  handle_creating_new_user,
  get_cart_by_query_obj,
  adminUserProfilePageInitialDataApi,
};
