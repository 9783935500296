import axios from "axios";
const BaseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("ACCESS_TOKEN");

export const newErrorCreationApi = async (errorInfos) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/admin/error/new_error`, {
      errorInfos,
    });

    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};
export const getErrorsApi = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/error/get_errors_by_query`,
      {
        queryObject,
        skip,
        limit,
        sortingObj,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};
