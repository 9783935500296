import { createContext, useContext, useEffect, useState } from "react";
import { signUpApi, signInApi } from "../api/userApi";
import { toast } from "react-toastify";
import StoreContext from "./StoreContext";
import { isBrowser, isMobile } from "react-device-detect";
import axios from "axios";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [adminState, setAdminState] = useState({
    isLoading: true,
    admin: null,
  });

  // ------------- context --------------
  const { BaseUrl } = useContext(StoreContext);

  // ----------- LOCAL STORAGE ----------
  const LC_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const LC_ADMIN_INFO = JSON.parse(localStorage.getItem("ADMIN_INFO"));

  // ------------------------ useEffect  ------------------------------

  useEffect(() => {
    setAdminState({
      ...adminState,
      isLoading: false,
      admin: LC_TOKEN,
      adminInfo: LC_ADMIN_INFO,
    });
    // eslint-disable-next-line
  }, []);

  // ----------------------- functions -------------------------

  const logout = () => {
    localStorage.removeItem("ACCESS_TOKEN");
    setAdminState({
      ...adminState,
      admin: null,
      adminInfo: null,
    });
  };

  /**
   * Signup context function
   */
  const signUp = async (formData) => {
    try {
      setAdminState({
        ...adminState,
        isLoading: true,
      });
      const { mobile, userName, password, eboCode } = formData;

      const res = await signUpApi({
        BaseUrl: BaseUrl,
        userName: userName,
        password: password,
        mobile: mobile,
        eboCode: eboCode,
      });

      if (res.isSuccess) {
        toast.warning("Loged in successfully");
        localStorage.setItem("ACCESS_TOKEN", res.data);
        localStorage.setItem("ADMIN_INFO", JSON.stringify(res.adminInfo));
        window.location.reload();
      } else if (res.isAlreadyExist) {
        toast.warning("user already exists, try to login");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      } else {
        toast.warning("Not got the right response, try again");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      }
    } catch (error) {
      toast.warning("Got an error, checkout the console for more information");
      setAdminState({
        ...adminState,
        isLoading: false,
      });

      console.log(error);
    }
  };
  /**
   * Signin context function
   */
  const signIn = async (formData) => {
    try {
      setAdminState({
        ...adminState,
        isLoading: true,
      });
      const { mobile, password } = formData;

      const res = await signInApi({
        BaseUrl: BaseUrl,
        password: password,
        mobile: mobile,
      });

      if (res.isSuccess) {
        toast.warning("Loged in successfully");
        localStorage.setItem("ACCESS_TOKEN", res.data);
        localStorage.setItem("ADMIN_INFO", JSON.stringify(res.adminInfo));
        window.location.reload();
      } else {
        toast.warning("Not got the right response, try again");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      }
    } catch (error) {
      toast.warning("Got an error, checkout the console for more information");
      setAdminState({
        ...adminState,
        isLoading: false,
      });

      console.log(error);
    }
  };

  // useEffect to send the user vist to keep track of visited users in user Traffic management

  useEffect(() => {
    try {
      const fun = async () => {
        // Check if the API call was made recently (within the last X minutes)
        const lastAPICallTime = sessionStorage.getItem("lastAPICallTime");
        const currentTime = new Date().getTime();
        const minTimeDifference = 5 * 60 * 1000; // 5 minutes in milliseconds
        console.log(lastAPICallTime);
        if (
          !adminState.isLoading &&
          (!lastAPICallTime ||
            currentTime - lastAPICallTime >= minTimeDifference)
        ) {
          const trafficInfos = {
            userId: adminState.adminInfo ? adminState.adminInfo._id : null,
            isRegistered: adminState.adminInfo ? true : false,
            website: "admin",
            device: isMobile ? "mobile" : isBrowser ? "browser" : "notDefined",
          };

          // console.log(adminState, trafficInfos);
          await axios.post(`${BaseUrl}/api/admin/traffic`, {
            trafficInfos,
          });
          // Update the lastAPICallTime in sessionStorage
          sessionStorage.setItem("lastAPICallTime", currentTime.toString());
        }
      };

      fun();
    } catch (error) {
      console.log(error);
    }
  }, [adminState]);

  return (
    <AdminContext.Provider
      value={{
        adminState,
        setAdminState,
        signUp,
        signIn,
        LC_TOKEN,
        logout,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
