import axios from "axios";

export async function getHubsPageInitialDataApi() {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/hub/get_hubs_page_initial_data_api`,

      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
    return { isSuccess: false, message: error.message };
  }
}

export async function createNewHubApi(data) {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/admin/hub/create_new_hub`,
      { data },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error.message);
    return { isSuccess: false, message: error.message };
  }
}

export const get_hubs_by_query_obj = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
  projectionString,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/admin/hub/get_hubs_by_query_obj`,
      {
        queryObject: queryObject || {},
        skip: skip || 0,
        limit: limit || 1000,
        sortingObj: sortingObj || { createdAt: "desc" },
        projectionString: projectionString || "",
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false, isError: true, data: null, message: error };
  }
};

export const update_hubs_by_query_obj = async ({
  queryObject,
  updateObject,
  tags,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/admin/hub/update_hubs_by_query_obj`,
      {
        queryObject,
        updateObject,
        tags,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false, isError: true, data: null, message: error };
  }
};

export const delete_hub_api = async ({ _id }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/admin/hub/delete_hub`,
      {
        _id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { isSuccess: false, isError: true, data: null, message: error };
  }
};
